<template>
  <div class="main-view">
    Coming soon.
  </div>
</template>

<script>
export default {
  name: 'main-view'
}
</script>

<style lang="scss">
</style>
